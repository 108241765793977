import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StatsSection from './StatsSection';

const BASE_URL = 'https://back-webchat-934866038204.us-central1.run.app/api';

// Servicio de autenticación
const authService = {
  async login() {
    try {
      const response = await fetch(`${BASE_URL}/widget-auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: 'widget@caresse.com',
          password: 'CaresseWidget2024!'
        })
      });

      if (!response.ok) throw new Error('Error de autenticación');

      const data = await response.json();
      localStorage.setItem('token', data.access_token);
      return data.access_token;
    } catch (error) {
      console.error('Error en login:', error);
      throw error;
    }
  }
};

// Componente Alert
const Alert = ({ children, variant = 'error' }) => {
  const bgColor = variant === 'error' ? 'bg-red-50' : 'bg-yellow-50';
  const textColor = variant === 'error' ? 'text-red-800' : 'text-yellow-800';
  const borderColor = variant === 'error' ? 'border-red-200' : 'border-yellow-200';

  return (
    <div className={`p-4 rounded-lg border ${bgColor} ${borderColor}`}>
      <p className={textColor}>{children}</p>
    </div>
  );
};

// Sección de Instalación
const InstallationSection = ({ apiKey = "DEMO-API-KEY" }) => {
  const installCode = `
<!-- Agregar antes del cierre de </body> -->
<script>
  window.ultraDeepConfig = {
    apiKey: '${apiKey}',
    apiUrl: '${BASE_URL}',
    widgetUrl: 'https://webchat2-934866038204.us-central1.run.app'
  };
</script>
<script src="https://webchat2-934866038204.us-central1.run.app/widget-loader.js" async></script>
  `.trim();

  const handleCopy = () => {
    navigator.clipboard.writeText(installCode);
    toast.success('Código copiado al portapapeles');
  };

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <h2 className="text-xl font-semibold mb-4">Código de Instalación</h2>
      <p className="mb-4 text-gray-600">
        Para integrar el chat en tu sitio web, copia y pega este código antes del cierre del tag <code>&lt;/body&gt;</code>
      </p>
      <div className="relative">
        <pre className="bg-gray-50 p-4 rounded-lg overflow-x-auto text-sm">
          <code>{installCode}</code>
        </pre>
        <button
          onClick={handleCopy}
          className="absolute top-2 right-2 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 text-sm"
        >
          Copiar Código
        </button>
      </div>
    </div>
  );
};

// Sección de Configuración
const ConfigurationSection = ({ settings, loading, onSave }) => {
  const defaultSettings = {
    botName: 'UltraDeep Assistant',
    welcomeMessage: '¡Hola! Soy Max, asistente virtual.',
    headerText: 'Chat',
    buttonText: 'Chat',
    primaryColor: '#2563eb',
    buttonStyle: 'round',
    fontSize: 'medium',
    position: 'right',
    language: 'es',
    showTimestamp: true,
    soundEnabled: true,
    avatarEnabled: true
  };

  const [localSettings, setLocalSettings] = useState(settings || defaultSettings);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (settings) {
      setLocalSettings(settings);
    }
  }, [settings]);

  const handleChange = (key, value) => {
    setLocalSettings(prev => {
      const newSettings = { ...prev, [key]: value };
      setHasChanges(JSON.stringify(newSettings) !== JSON.stringify(settings));
      return newSettings;
    });
  };

  const handleSave = async () => {
    await onSave(localSettings);
    setHasChanges(false);
  };

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Configuración del Chat</h2>
        {hasChanges && (
          <div className="flex items-center gap-2">
            <Alert variant="warning">Hay cambios sin guardar</Alert>
            <button
              onClick={handleSave}
              disabled={loading}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
            >
              {loading ? (
                <span className="inline-block animate-spin">⌛</span>
              ) : (
                <span>💾</span>
              )}
              {loading ? 'Guardando...' : 'Guardar'}
            </button>
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Apariencia */}
        <div className="space-y-4">
          <h3 className="font-medium text-gray-700">Apariencia</h3>
          
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Color Principal
            </label>
            <div className="flex items-center gap-2">
              <input
                type="color"
                value={localSettings.primaryColor}
                onChange={e => handleChange('primaryColor', e.target.value)}
                className="h-10 w-20"
              />
              <input
                type="text"
                value={localSettings.primaryColor}
                onChange={e => handleChange('primaryColor', e.target.value)}
                className="border rounded px-2 py-1 w-28"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Estilo del Botón
            </label>
            <select
              value={localSettings.buttonStyle}
              onChange={e => handleChange('buttonStyle', e.target.value)}
              className="w-full border rounded px-3 py-2"
            >
              <option value="round">Redondo</option>
              <option value="square">Cuadrado</option>
              <option value="pill">Píldora</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Posición
            </label>
            <select
              value={localSettings.position}
              onChange={e => handleChange('position', e.target.value)}
              className="w-full border rounded px-3 py-2"
            >
              <option value="right">Derecha</option>
              <option value="left">Izquierda</option>
            </select>
          </div>
        </div>

        {/* Contenido */}
        <div className="space-y-4">
          <h3 className="font-medium text-gray-700">Contenido</h3>
          
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Nombre del Bot
            </label>
            <input
              type="text"
              value={localSettings.botName}
              onChange={e => handleChange('botName', e.target.value)}
              className="w-full border rounded px-3 py-2"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Mensaje de Bienvenida
            </label>
            <textarea
              value={localSettings.welcomeMessage}
              onChange={e => handleChange('welcomeMessage', e.target.value)}
              className="w-full border rounded px-3 py-2"
              rows={2}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Texto del Botón
            </label>
            <input
              type="text"
              value={localSettings.buttonText}
              onChange={e => handleChange('buttonText', e.target.value)}
              className="w-full border rounded px-3 py-2"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Texto del Header
            </label>
            <input
              type="text"
              value={localSettings.headerText}
              onChange={e => handleChange('headerText', e.target.value)}
              className="w-full border rounded px-3 py-2"
            />
          </div>
        </div>

        {/* Preferencias */}
        <div className="space-y-4">
          <h3 className="font-medium text-gray-700">Preferencias</h3>
          
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Tamaño de Fuente
            </label>
            <select
              value={localSettings.fontSize}
              onChange={e => handleChange('fontSize', e.target.value)}
              className="w-full border rounded px-3 py-2"
            >
              <option value="small">Pequeño</option>
              <option value="medium">Mediano</option>
              <option value="large">Grande</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Idioma
            </label>
            <select
              value={localSettings.language}
              onChange={e => handleChange('language', e.target.value)}
              className="w-full border rounded px-3 py-2"
            >
              <option value="es">Español</option>
              <option value="en">English</option>
            </select>
          </div>
        </div>

        {/* Características */}
        <div className="space-y-4">
          <h3 className="font-medium text-gray-700">Características</h3>
          
          <div className="space-y-3">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={localSettings.showTimestamp}
                onChange={e => handleChange('showTimestamp', e.target.checked)}
                className="rounded"
              />
              <span className="text-sm">Mostrar hora en mensajes</span>
            </label>

            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={localSettings.soundEnabled}
                onChange={e => handleChange('soundEnabled', e.target.checked)}
                className="rounded"
              />
              <span className="text-sm">Sonidos habilitados</span>
            </label>

            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={localSettings.avatarEnabled}
                onChange={e => handleChange('avatarEnabled', e.target.checked)}
                className="rounded"
              />
              <span className="text-sm">Mostrar avatar del bot</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminDashboard = () => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(Date.now());


  const ensureAuth = async () => {
    try {
      if (!localStorage.getItem('token')) {
        await authService.login();
      }
    } catch (err) {
      console.error('Error en autenticación:', err);
      setError('Error de autenticación');
      toast.error('Error de autenticación');
    }
  };

  const fetchConfig = async () => {
    try {
      setLoading(true);
      await ensureAuth();
      
      const response = await fetch(`${BASE_URL}/widget-config?v=${Date.now()}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Cache-Control': 'no-cache'
        }
      });

      if (response.status === 401) {
        await authService.login();
        return fetchConfig();
      }
      
      if (!response.ok) throw new Error('Error al cargar la configuración');
      
      const data = await response.json();
      data.version = Date.now(); // Añadir versión para control de cambios
      setSettings(data);
      setLastUpdate(Date.now());
      setError(null);
    } catch (err) {
      console.error('Error fetchConfig:', err);
      setError('Error al cargar la configuración');
      toast.error('Error al cargar la configuración');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSettings = async (newSettings) => {
    try {
      setLoading(true);
      setError(null);
      await ensureAuth();
      
      const configToSave = {
        ...newSettings,
        version: Date.now()
      };
      
      const response = await fetch(`${BASE_URL}/widget-config`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(configToSave)
      });
  
      if (!response.ok) throw new Error('Error al guardar la configuración');
  
      const updatedConfig = await response.json();
      setSettings(updatedConfig);
      
      // Emitir evento para todos los widgets en la página
      const event = new CustomEvent('configUpdate', { 
        detail: updatedConfig,
        bubbles: true,
        composed: true
      });
  
      // Emitir en el objeto window para asegurar que llegue al widget
      window.dispatchEvent(event);
      
      // También emitir en el chatWidgetEvents por compatibilidad
      window.chatWidgetEvents?.dispatchEvent(event);
      
      console.log('Evento de actualización emitido:', updatedConfig);
      
      setLastUpdate(Date.now());
      toast.success('¡Configuración actualizada con éxito!');
    } catch (err) {
      console.error('Error:', err);
      setError('Error al guardar la configuración');
      toast.error('Error al guardar los cambios');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfig();
    
    const pollInterval = setInterval(() => {
      fetchConfig();
    }, 30000);

    return () => clearInterval(pollInterval);
  }, []);

  if (!settings && !error) {
    return (
      <div className="min-h-screen bg-gray-50 p-4 flex items-center justify-center">
        <div className="animate-spin text-blue-600 text-2xl">⌛</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="max-w-7xl mx-auto">
        <ToastContainer 
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        
        <StatsSection />
        
        {error && (
          <Alert variant="error">
            {error}
          </Alert>
        )}

        <ConfigurationSection 
          settings={settings}
          loading={loading}
          onSave={handleSaveSettings}
        />

        <InstallationSection />
      </div>
    </div>
  );
};

export default AdminDashboard;