import React, { useState, useEffect } from 'react';
import AdminDashboard from './components/AdminDashboard';
import ChatWidget from './components/ChatWidget';
import { ToastContainer, toast } from 'react-toastify';
import { chatService } from './services/api';
import { authService } from './services/auth.service';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [chatConfig, setChatConfig] = useState({
    apiUrl: 'https://back-webchat-934866038204.us-central1.run.app/api',
    widgetUrl: 'https://webchat2-934866038204.us-central1.run.app',
    welcomeMessage: '¡Hola! Soy Max, asistente virtual de Ultra Deep Tech.',
    theme: 'blue',
    position: 'right',
    botName: 'Max',
    headerText: 'Ultra Deep Tech Chat',
    buttonText: 'Chat',
    primaryColor: '#2563eb',
    fontSize: 'medium',
    showTimestamp: true,
    soundEnabled: true,
    language: 'es',
    buttonStyle: 'round',
    avatarEnabled: true,
    version: Date.now()
  });

  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        // Login
        const authData = await authService.login();
        setToken(authData.access_token);

        // Cargar configuración del backend
        const backendConfig = await chatService.getWidgetConfig(authData.access_token);
        if (backendConfig) {
          setChatConfig(prev => ({
            ...prev,
            ...backendConfig,
            version: Date.now()
          }));
        }

        // Escuchar eventos de actualización
        window.chatWidgetEvents = new EventTarget();
        window.chatWidgetEvents.addEventListener('configUpdate', handleConfigEvent);

        setIsConfigLoaded(true);
      } catch (error) {
        console.error('Error initializing:', error);
        toast.error('Error al cargar la configuración');
      }
    };

    initializeApp();

    return () => {
      window.chatWidgetEvents?.removeEventListener('configUpdate', handleConfigEvent);
    };
  }, []);

  // Manejador de eventos de configuración
  const handleConfigEvent = (event) => {
    const newConfig = event.detail;
    if (newConfig.version > chatConfig.version) {
      setChatConfig(newConfig);
    }
  };

  const handleConfigUpdate = async (newConfig) => {
    try {
      if (!token) {
        const authData = await authService.login();
        setToken(authData.access_token);
      }

      const updatedConfig = await chatService.saveWidgetConfig(token, {
        ...newConfig,
        version: Date.now()
      });

      setChatConfig(updatedConfig);
      toast.success('Configuración actualizada');
    } catch (error) {
      console.error('Error saving config:', error);
      toast.error('Error al guardar la configuración');
    }
  };

  const handleResetConfig = async () => {
    try {
      const defaultConfig = {
        ...chatConfig,
        version: Date.now()
      };
      await handleConfigUpdate(defaultConfig);
    } catch (error) {
      console.error('Error resetting config:', error);
      toast.error('Error al restablecer la configuración');
    }
  };

  if (!isConfigLoaded) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-blue-500">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Cargando configuración...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <AdminDashboard 
        currentConfig={chatConfig} 
        onConfigUpdate={handleConfigUpdate}
        onResetConfig={handleResetConfig}
      />
      <ChatWidget 
        config={chatConfig}
        onConfigUpdate={handleConfigUpdate}
      />
      <ToastContainer 
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;