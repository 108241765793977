const BASE_URL = 'https://back-webchat-934866038204.us-central1.run.app/api';

export const chatService = {
  async getWidgetConfig(token) {
    try {
      if (!token) {
        const authData = await this.login();
        token = authData.access_token;
      }

      const response = await fetch(`${BASE_URL}/widget-config?v=${Date.now()}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Cache-Control': 'no-cache'
        }
      });

      if (response.status === 401) {
        const authData = await this.login();
        return this.getWidgetConfig(authData.access_token);
      }

      if (!response.ok) throw new Error('Error al obtener configuración');
      return response.json();
    } catch (error) {
      console.error('Error en getWidgetConfig:', error);
      throw error;
    }
  },

  async saveWidgetConfig(token, config) {
    const configWithVersion = {
      ...config,
      version: Date.now()
    };

    const response = await fetch(`${BASE_URL}/widget-config`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Cache-Control': 'no-cache'
      },
      body: JSON.stringify(configWithVersion)
    });

    if (!response.ok) {
      if (response.status === 401) {
        const authData = await this.login();
        return this.saveWidgetConfig(authData.access_token, config);
      }
      throw new Error('Error al guardar configuración');
    }

    return response.json();
  },

  async login() {
    const response = await fetch(`${BASE_URL}/widget-auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: 'widget@caresse.com',
        password: 'CaresseWidget2024!'
      })
    });
    
    if (!response.ok) throw new Error('Error de autenticación');
    const data = await response.json();
    localStorage.setItem('token', data.access_token);
    return data;
  }
};