import React, { useState, useRef, useEffect, useCallback } from 'react';

const ChatWidget = ({ config }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);

  const {
    apiUrl = 'https://back-webchat-934866038204.us-central1.run.app',
    welcomeMessage = '¡Hola! Soy Max, asistente virtual.',
    position = 'right',
    headerText = 'Chat',
    buttonText = 'Chat',
    primaryColor = '#2563eb',
    fontSize = 'medium',
    showTimestamp = true,
    soundEnabled = true,
    buttonStyle = 'round',
    avatarEnabled = true,
    botName = 'Max',
    language = 'es'
  } = config || {};

  // Scroll al último mensaje
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const login = useCallback(async () => {
    try {
      setError(null);
      const loginUrl = `${apiUrl}/widget-auth/login`;
      
      const response = await fetch(loginUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          email: 'widget@caresse.com',
          password: 'CaresseWidget2024!'
        })
      });

      if (!response.ok) {
        throw new Error(`Error de autenticación: ${response.status}`);
      }

      const data = await response.json();
      setToken(data.access_token);
      setError(null);
    } catch (error) {
      setError(error.message || 'Error al intentar conectar con el servidor');
      setToken(null);
    }
  }, [apiUrl]);

  useEffect(() => {
    login();
  }, [login]);

  const handleSend = async () => {
    if (!inputMessage.trim() || !token) return;

    const newMessage = {
      text: inputMessage.trim(),
      sender: 'user',
      timestamp: new Date().toLocaleTimeString()
    };

    setMessages(prev => [...prev, newMessage]);
    setInputMessage('');
    setIsLoading(true);
    setError(null);

    try {
      const chatUrl = `${apiUrl}/texttospeech/widget/chat`;
      const response = await fetch(chatUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: inputMessage.trim(),
          type: "chat",
          language
        })
      });

      if (!response.ok) {
        if (response.status === 401) {
          await login();
          throw new Error('Por favor, intenta nuevamente.');
        }
        throw new Error(`Error en la comunicación: ${response.status}`);
      }

      const botResponse = {
        text: await response.text(),
        sender: 'bot',
        timestamp: new Date().toLocaleTimeString()
      };
      
      if (soundEnabled) {
        const audio = new Audio('/notification.mp3');
        audio.play().catch(() => {});
      }
      
      setMessages(prev => [...prev, botResponse]);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
      setMessages(prev => [...prev, {
        text: "Lo siento, ocurrió un error en la comunicación. Por favor, intenta nuevamente.",
        sender: 'bot',
        timestamp: new Date().toLocaleTimeString()
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const getButtonClasses = () => {
    const baseClasses = `fixed ${position}-4 bottom-4 z-50 text-white p-4 shadow-lg transition-all duration-200 hover:shadow-xl`;
    const styleClasses = {
      round: 'rounded-full',
      square: 'rounded-lg',
      pill: 'rounded-full px-6'
    };
    return `${baseClasses} ${styleClasses[buttonStyle] || styleClasses.round}`;
  };

  const getFontSize = () => {
    const sizes = {
      small: 'text-sm',
      medium: 'text-base',
      large: 'text-lg'
    };
    return sizes[fontSize] || sizes.medium;
  };

  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={getButtonClasses()}
        style={{ backgroundColor: primaryColor }}
      >
        {isOpen ? (
          <span className="text-2xl">×</span>
        ) : (
          <div className="flex items-center gap-2">
            <span className="text-xl">💬</span>
            <span className={getFontSize()}>{buttonText}</span>
          </div>
        )}
      </button>

      {isOpen && (
        <div 
          className={`fixed ${position}-4 bottom-16 w-80 h-96 bg-white rounded-lg shadow-xl flex flex-col z-50 transition-all duration-200`}
        >
          {/* ... (resto del código igual hasta el botón de enviar) */}
          <div className="p-4 border-t">
            <div className="flex gap-2">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                placeholder="Escribe un mensaje..."
                className={`flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 ${getFontSize()}`}
                style={{ 
                  focusRingColor: primaryColor,
                  borderColor: error ? '#ef4444' : '#e5e7eb' 
                }}
                disabled={!token || isLoading}
              />
              <button
                onClick={handleSend}
                className="px-4 py-2 text-white rounded-lg hover:opacity-90 disabled:opacity-50 flex items-center gap-2"
                style={{ backgroundColor: primaryColor }}
                disabled={isLoading || !token || !inputMessage.trim()}
              >
                {isLoading ? (
                  <span className="animate-spin">⌛</span>
                ) : (
                  <span>➤</span>
                )}
                <span>Enviar</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWidget;