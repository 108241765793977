// services/auth.service.js
const BASE_URL = 'https://back-webchat-934866038204.us-central1.run.app/api';

export const authService = {
  async login() {
    try {
      const response = await fetch(`${BASE_URL}/widget-auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: 'widget@caresse.com',
          password: 'CaresseWidget2024!'
        })
      });

      if (!response.ok) throw new Error('Error de autenticación');

      const data = await response.json();
      localStorage.setItem('token', data.access_token);
      return data.access_token;
    } catch (error) {
      console.error('Error en login:', error);
      throw error;
    }
  }
};